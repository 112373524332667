// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Debounce = require("rescript-debounce/src/Debounce.bs.js");

var make = Debounce.make;

var makeControlled = Debounce.makeControlled;

exports.make = make;
exports.makeControlled = makeControlled;
/* No side effect */
