// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var RelayEnv = require("../../../RelayEnv.bs.js");
var ReasonPromise = require("../../../reason/ReasonPromise.bs.js");
var Utils__Promise = require("../../../utils/Utils__Promise.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var EmailRegistrationStatusQuery_graphql = require("../../../__generated__/EmailRegistrationStatusQuery_graphql.bs.js");

var convertVariables = EmailRegistrationStatusQuery_graphql.Internal.convertVariables;

var convertResponse = EmailRegistrationStatusQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = EmailRegistrationStatusQuery_graphql.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, EmailRegistrationStatusQuery_graphql.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, EmailRegistrationStatusQuery_graphql.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(EmailRegistrationStatusQuery_graphql.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(EmailRegistrationStatusQuery_graphql.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(EmailRegistrationStatusQuery_graphql.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(EmailRegistrationStatusQuery_graphql.node, convertVariables, param, param$1);
}

var Query_emailRegistrationStatus_decode = EmailRegistrationStatusQuery_graphql.Utils.emailRegistrationStatus_decode;

var Query_emailRegistrationStatus_fromString = EmailRegistrationStatusQuery_graphql.Utils.emailRegistrationStatus_fromString;

var Query = {
  emailRegistrationStatus_decode: Query_emailRegistrationStatus_decode,
  emailRegistrationStatus_fromString: Query_emailRegistrationStatus_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function queryFn(emailAddress) {
  return ReasonPromise.map(Utils__Promise.fromJsPromise(fetchPromised(RelayEnv.environment, {
                      emailAddress: emailAddress
                    }, undefined, undefined, undefined)), (function (v) {
                if (v.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: {
                            NAME: "REJECTED",
                            VAL: v._0
                          }
                        };
                }
                var match = v._0.emailRegistrationStatus;
                if (match === undefined) {
                  return {
                          TAG: "Error",
                          _0: "EMPTY_RESPONSE"
                        };
                }
                var status = match.status;
                if (status === "AVAILABLE" || status === "REGISTERED") {
                  return {
                          TAG: "Ok",
                          _0: status
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: "UNSELECTED_ENUM"
                        };
                }
              }));
}

exports.Query = Query;
exports.queryFn = queryFn;
/* RelayEnv Not a pure module */
