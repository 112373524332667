// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../../../../utils/Utils.bs.js");
var React = require("react");
var $dot$dot$slash$dot$dot$slash$dot$dot$slash = require("../../../");
var Icon__Jsx3 = require("../Icon/Icon__Jsx3.bs.js");
var View__Jsx3 = require("../../helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Form__Label = require("./Form__Label.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Theme__Color = require("../../../../reason/common/Theme/Theme__Color.bs.js");
var Touchable__Jsx3 = require("../Touchable/Touchable__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Colors = require("uikit/styles/colors");
var TextareaField = require("../../../atoms/Form/TextareaField");

function autocompleteToString(autocomplete) {
  if (typeof autocomplete === "object") {
    return autocomplete.VAL;
  } else if (autocomplete === "new_password") {
    return "new_password";
  } else if (autocomplete === "username") {
    return "username";
  } else {
    return "current_password";
  }
}

var make = $dot$dot$slash$dot$dot$slash$dot$dot$slash.InputField;

var JS = {
  make: make
};

function Form__Jsx3$InputField(props) {
  return JsxRuntime.jsx(make, {
              labelText: props.labelText,
              type: props._type,
              autocomplete: Core__Option.map(props.autocomplete, autocompleteToString),
              autofocus: props.autofocus,
              optionalText: props.optionalText,
              placeholderText: props.placeholderText,
              className: props.className,
              onChange: props.onChange,
              onKeyDown: props.onKeyDown,
              onBlur: props.onBlur,
              disabled: props.disabled,
              onFocus: props.onFocus,
              status: props.status,
              prepend: props.prepend,
              append: props.append,
              value: props.value,
              defaultValue: props.defaultValue,
              id: props.id,
              ref: props.inputRef
            });
}

var InputField = {
  JS: JS,
  make: Form__Jsx3$InputField
};

var make$1 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.TextareaField;

var TextAreaField = {
  make: make$1
};

var make$2 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.PriceInputField;

var PriceInputField = {
  make: make$2
};

var make$3 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.Checkbox;

var Checkbox = {
  make: make$3
};

var make$4 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.Radio;

var Radio = {
  make: make$4
};

var make$5 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.RadioGroup;

var RadioGroup = {
  make: make$5
};

var make$6 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.RadioGroupItem;

var RadioGroupItem = {
  make: make$6
};

var make$7 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.Switch;

var Switch = {
  make: make$7
};

var style = {
  color: Colors.crimson,
  display: "block",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "16px",
  marginTop: "5px"
};

function Form__Jsx3$Error(props) {
  return JsxRuntime.jsx("span", {
              children: props.children,
              style: style
            });
}

var $$Error = {
  style: style,
  make: Form__Jsx3$Error
};

var style$1 = {
  color: Colors.goldTips,
  display: "block",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "16px",
  marginTop: "5px"
};

function Form__Jsx3$Warning(props) {
  return JsxRuntime.jsx("span", {
              children: props.children,
              style: style$1
            });
}

var Warning = {
  style: style$1,
  make: Form__Jsx3$Warning
};

function submitForm(form) {
  form.dispatchEvent(new Event("submit", {
            cancelable: true
          }));
}

var make$8 = TextareaField.StyledTextarea;

var StyledTextarea = {
  make: make$8
};

function Form__Jsx3$Textarea(props) {
  var inputRef = props.inputRef;
  var onKeyDown = props.onKeyDown;
  var __rows = props.rows;
  var onChangeProp = props.onChange;
  var rows = __rows !== undefined ? __rows : 1;
  var internalRef = React.useRef(null);
  var onChange = function (e) {
    var current = internalRef.current;
    if (!(current == null)) {
      current.setAttribute("style", "height:auto");
      var h = current.scrollHeight + 2 | 0;
      current.setAttribute("style", "height:" + (String(h) + "px"));
    }
    Curry._1(onChangeProp, e);
  };
  var onKeyDown$1 = function (e) {
    var current = internalRef.current;
    var key = e.key;
    var cmd = e.metaKey;
    if (key === "Enter" && cmd) {
      e.preventDefault();
      e.stopPropagation();
      var form = Core__Option.flatMap((current == null) ? undefined : Caml_option.some(current), (function (c) {
              return Caml_option.null_to_opt(c.form);
            }));
      if (form !== undefined) {
        submitForm(Caml_option.valFromOption(form));
      }
      
    }
    if (onKeyDown !== undefined) {
      return Curry._1(onKeyDown, e);
    }
    
  };
  return JsxRuntime.jsx(make$8, {
              placeholder: props.placeholder,
              onChange: onChange,
              onBlur: props.onBlur,
              onClick: props.onClick,
              onKeyDown: onKeyDown$1,
              onKeyPress: props.onKeyPress,
              onKeyUp: props.onKeyUp,
              onFocus: props.onFocus,
              status: props.status,
              value: props.value,
              id: props.id,
              className: props.className,
              rows: rows,
              disabled: props.disabled,
              ref: Caml_option.some(function (i) {
                    Utils.React.setCurrentRef(internalRef, i);
                    if (inputRef !== undefined) {
                      return Utils.React.setCurrentRef(inputRef, i);
                    }
                    
                  })
            });
}

var Textarea = {
  StyledTextarea: StyledTextarea,
  make: Form__Jsx3$Textarea
};

var style_0 = Css.position("absolute");

var style_1 = {
  hd: Css.right({
        NAME: "px",
        VAL: 7
      }),
  tl: {
    hd: Css.bottom({
          NAME: "px",
          VAL: 7
        }),
    tl: {
      hd: Css.height({
            NAME: "px",
            VAL: 21
          }),
      tl: {
        hd: Css.width({
              NAME: "px",
              VAL: 21
            }),
        tl: {
          hd: Css.borderRadius({
                NAME: "px",
                VAL: 11
              }),
          tl: {
            hd: Css.paddingLeft({
                  NAME: "px",
                  VAL: 1
                }),
            tl: {
              hd: Css.backgroundColor(Theme__Color.stTropaz),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.color(Theme__Color.white),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var style$2 = {
  hd: style_0,
  tl: style_1
};

function Form__Jsx3$SearchField$SearchFieldGoButton(props) {
  return JsxRuntime.jsx(Touchable__Jsx3.make, {
              style: style$2,
              onPress: props.onPress,
              children: JsxRuntime.jsx(Icon__Jsx3.make, {
                    icon: "ArrowRight",
                    size: 9
                  })
            });
}

var SearchFieldGoButton = {
  style: style$2,
  make: Form__Jsx3$SearchField$SearchFieldGoButton
};

var style_0$1 = Css.position("absolute");

var style_1$1 = {
  hd: Css.right({
        NAME: "px",
        VAL: 9
      }),
  tl: {
    hd: Css.bottom({
          NAME: "px",
          VAL: 7
        }),
    tl: {
      hd: Css.height({
            NAME: "px",
            VAL: 21
          }),
      tl: {
        hd: Css.width({
              NAME: "px",
              VAL: 21
            }),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.color(Theme__Color.silverSand),
                tl: {
                  hd: Css.hover({
                        hd: Css.color(Theme__Color.doveGray),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var style$3 = {
  hd: style_0$1,
  tl: style_1$1
};

function Form__Jsx3$SearchField$SearchFieldClearButton(props) {
  return JsxRuntime.jsx(Touchable__Jsx3.make, {
              style: style$3,
              onPress: props.onPress,
              children: JsxRuntime.jsx(Icon__Jsx3.make, {
                    icon: "ReactSelectCancel",
                    size: 20
                  })
            });
}

var SearchFieldClearButton = {
  style: style$3,
  make: Form__Jsx3$SearchField$SearchFieldClearButton
};

function toArray(str) {
  return str.trim().split(",").map(function (prim) {
                return prim.trim();
              }).filter(function (str) {
              return str !== "";
            });
}

function fromArray(arr) {
  return Utils.$$Array.joinWith(", ", arr);
}

function Form__Jsx3$SearchField(props) {
  var __onFocus = props.onFocus;
  var __onBlur = props.onBlur;
  var __onChange = props.onChange;
  var value = props.value;
  var onChange = __onChange !== undefined ? __onChange : (function (prim) {
        
      });
  var onBlur = __onBlur !== undefined ? __onBlur : (function (prim) {
        
      });
  var onFocus = __onFocus !== undefined ? __onFocus : (function (prim) {
        
      });
  var match = React.useState(function (param) {
        return Utils.$$Array.joinWith(", ", value);
      });
  var setTempValue = match[1];
  var tempValue = match[0];
  React.useEffect((function (param) {
          Curry._1(setTempValue, (function (param) {
                  return Utils.$$Array.joinWith(", ", value);
                }));
        }), [value]);
  return JsxRuntime.jsxs(View__Jsx3.make, {
              flexGrow: 1,
              position: "relative",
              children: [
                JsxRuntime.jsx(Form__Jsx3$InputField, {
                      labelText: props.labelText,
                      _type: "search",
                      placeholderText: props.placeholderText,
                      className: Curry._1(Css.style, {
                            hd: Css.flexGrow(1),
                            tl: /* [] */0
                          }),
                      onChange: (function (e) {
                          var newValue = e.target.value;
                          Curry._1(setTempValue, (function (param) {
                                  return newValue;
                                }));
                        }),
                      onKeyDown: (function (e) {
                          if (e.key === "Enter") {
                            return Curry._1(onChange, toArray(tempValue));
                          }
                          
                        }),
                      onBlur: (function (e) {
                          Curry._1(onBlur, e);
                          Curry._1(onChange, toArray(tempValue));
                        }),
                      onFocus: Curry.__1(onFocus),
                      value: tempValue
                    }),
                tempValue.trim() !== Utils.$$Array.joinWith(", ", value) ? JsxRuntime.jsx(Form__Jsx3$SearchField$SearchFieldGoButton, {
                        onPress: (function (param) {
                            Curry._1(onChange, toArray(tempValue));
                          })
                      }) : (
                    value.length !== 0 ? JsxRuntime.jsx(Form__Jsx3$SearchField$SearchFieldClearButton, {
                            onPress: (function (param) {
                                Curry._1(setTempValue, (function (param) {
                                        return "";
                                      }));
                                Curry._1(onChange, []);
                              })
                          }) : null
                  )
              ]
            });
}

var SearchField = {
  SearchFieldGoButton: SearchFieldGoButton,
  SearchFieldClearButton: SearchFieldClearButton,
  toArray: toArray,
  fromArray: fromArray,
  make: Form__Jsx3$SearchField
};

var Label;

var HelpLabel;

var RadioButton;

var RadioButtonGroup;

var label = Form__Label.make;

var inputField = Form__Jsx3$InputField;

var textAreaField = make$1;

var priceInputField = make$2;

var checkbox = make$3;

var radio = make$4;

var $$switch = make$7;

exports.Label = Label;
exports.HelpLabel = HelpLabel;
exports.autocompleteToString = autocompleteToString;
exports.InputField = InputField;
exports.TextAreaField = TextAreaField;
exports.PriceInputField = PriceInputField;
exports.Checkbox = Checkbox;
exports.Radio = Radio;
exports.RadioGroup = RadioGroup;
exports.RadioGroupItem = RadioGroupItem;
exports.RadioButton = RadioButton;
exports.RadioButtonGroup = RadioButtonGroup;
exports.Switch = Switch;
exports.label = label;
exports.inputField = inputField;
exports.textAreaField = textAreaField;
exports.priceInputField = priceInputField;
exports.checkbox = checkbox;
exports.radio = radio;
exports.$$switch = $$switch;
exports.$$Error = $$Error;
exports.Warning = Warning;
exports.submitForm = submitForm;
exports.Textarea = Textarea;
exports.SearchField = SearchField;
/* make Not a pure module */
