// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CssJs = require("bs-css-emotion/src/CssJs.bs.js");
var Media = require("../../../reason/common/Media/Media.bs.js");

var style = [Media.maxWidthJs("md", [CssJs.selector("* > :is(input, textarea)", [CssJs.fontSize({
                    NAME: "px",
                    VAL: 16
                  })])])];

var className = CssJs.style(style);

exports.style = style;
exports.className = className;
/* style Not a pure module */
